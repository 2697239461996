import type { Themes } from "@/context/StyledComponents/types/types";
import type { Environments } from "./types";

const environments: Environments = {
    globalTheme: process.env.NEXT_PUBLIC_GLOBAL_THEME as Themes,
    apiUrl: process.env.NEXT_PUBLIC_API_URL ?? "",
    webUrl: process.env.NEXT_PUBLIC_WEB_URL ?? "",
    isProduction: process.env.NEXT_PUBLIC_IS_PRODUCTION === "true"
};

export default environments;
