import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { dashboardReducer } from "./slices/dashboard/slice";
import { uiReducer } from "./slices/ui/slice";

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === "true";

export const store = configureStore({
    reducer: {
        ui: uiReducer,
        dashboard: dashboardReducer
    },
    devTools: !isProduction,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: true
        })
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
