"use client";

import useDashboardActions from "@/hooks/dashboard/useDashboardActions";
import { isObjectEqual } from "@/utils/utils";
import { useEffect } from "react";

const LayoutDashboardGuild: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { guild, guildConfig, toggleAreChanges } = useDashboardActions();

    useEffect(() => {
        const isConfigEqual = isObjectEqual(guildConfig, guild?.config ?? {});

        toggleAreChanges(!isConfigEqual);
    }, [guildConfig, guild?.config, toggleAreChanges]);

    return <main>{children}</main>;
};

export default LayoutDashboardGuild;
