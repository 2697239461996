"use client";

import useDashboard from "@/hooks/dashboard/useDashboard";
import useDashboardActions from "@/hooks/dashboard/useDashboardActions";
import { useEffect, useState } from "react";

type LayoutUserProps = {
    children: React.ReactNode;
    isCookieSession: boolean;
};

const LayoutUser: React.FC<LayoutUserProps> = ({ children, isCookieSession }) => {
    const { getUser } = useDashboard();
    const { user, loadUser, removeUser, changeUserState } = useDashboardActions();

    const [isInvalidCookie, setIsInvalidCookie] = useState<boolean>(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                changeUserState("loading");

                const userData = await getUser();
                if (userData?.id) loadUser(userData);

                if (userData === undefined && isCookieSession) {
                    setIsInvalidCookie(true);
                    changeUserState("empty");

                    return;
                }

                changeUserState(userData?.id ? "loaded" : "empty");
            } catch {
                if (user?.id) removeUser();

                changeUserState("error");
            }
        };

        if (!user?.id && isCookieSession && !isInvalidCookie) void fetchUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadUser, removeUser, user?.id]);

    return <main>{children}</main>;
};

export default LayoutUser;
