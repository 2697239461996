import type { Globals } from "@/context/StyledComponents/types/types";
import globalColors from "./globalColors";
import globalFonts from "./globalFonts";
import globalSizes from "./globalSizes";

const globals: Globals = {
    fonts: globalFonts,
    sizes: globalSizes,
    colors: globalColors
};

export default globals;
