import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-free/css/all.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/context/StyledComponents/globals/globalFonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/context/StyledComponents/globals/globalFonts.ts\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Head/Head.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/context/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/tailwindcss.css");
