import type { GlobalSizes } from "@/context/StyledComponents/types/types";

const globalSizes: GlobalSizes = {
    mobileMargin: "10px 30px 0 30px",
    desktopMargin: "10px 60px 0 60px",

    // Responsives
    mobileSmall: "360px",
    mobileMedium: "480px",
    mobileLarge: "768px",

    desktopSmall: "1024px",
    desktopMedium: "1200px",
    desktopLarge: "1280px"
};

export default globalSizes;
