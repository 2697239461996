// const REGEX_URL_DASHBOARD = /dashboard(?:\/(\d{17,19}))?(?:\/([1-4]|custom))?(?:\/\w+)?\/?$/;
const REGEX_IS_DASHBOARD = /dashboard?/;
const REGEX_GUILD_ID = /dashboard\/(\d{17,19})?/;
const REGEX_BOT_TYPE = /dashboard\/\d{17,19}\/([1-4]|custom)?(?:\/\w+)?\/?$/;

export const msFormat = (ms: number): string => {
    let s = Math.floor(ms / 1000);
    // const h = Math.floor(s / 3600);
    // s %= 3600;
    const m = Math.floor(s / 60);
    s %= 60;

    return `${m}m${s}s`;
};

export const getTime = (ms: number) => {
    const time = msFormat(ms);
    const parsedTime = /(\d+)m(\d+)s/.exec(time) ?? [0, 0, 0, 0];
    const m = Number(parsedTime[1]) || 0;
    const s = Number(parsedTime[2]) || 0;

    return { m, s };
};

export const stringFormat = (time: string): number => {
    const parsedTime = /(\d+)m(\d+)s/.exec(time) ?? [0, 0, 0];
    const m = Number(parsedTime[1]) || 0;
    const s = Number(parsedTime[2]) || 0;
    const ms = (m * 60 + s) * 1000;

    return ms;
};

export const formatNumber = (value: number): string => new Intl.NumberFormat("en-US").format(value).replace(/,/g, ".");

export const roundToBanlles = (value: number): string => {
    const valueParse = new Intl.NumberFormat("en-US").format(value).replace(/,/g, ".");

    return `${valueParse.split(".")[0]}.000`;
};

export const roundToTutitoos = (value: number) => {
    let valueParse = new Intl.NumberFormat("en-US").format(value).replace(/,/g, ".");
    const words = valueParse.split("");
    if (words.length > 3) valueParse = Math.ceil(Number.parseFloat(words.slice(0, words.indexOf(".") + 3).join(""))).toString();
    else {
        valueParse = Math.ceil(Number.parseFloat(`${words[0]}.${words.slice(1, words.length).join("")}`)).toString();
    }

    if (words.filter(word => word !== ".").length > 6) return `${valueParse}M`;
    if (words.length <= 3) {
        valueParse += words
            .map(() => "0")
            .join("")
            .slice(1, words.length);
        return new Intl.NumberFormat("en-US").format(Number.parseInt(valueParse, 10)).replace(/,/g, ".");
    }

    if (words.length > 3) {
        valueParse += words
            .map(() => "0")
            .join("")
            .slice(0, -words.indexOf(".") - 1);
        return new Intl.NumberFormat("en-US").format(Number.parseInt(valueParse, 10)).replace(/,/g, ".");
    }
};

export const roundToMarkox361 = (value: number): string => {
    // Convert the number to its absolute value
    const absValue = Math.abs(value);

    // Check if the number is greater than or equal to 1 billion
    if (absValue >= 1.0e9) return `${(absValue / 1.0e9).toFixed(2)}B`;

    // Check if the number is greater than or equal to 1 million
    if (absValue >= 1.0e6) return `${(absValue / 1.0e6).toFixed(2)}M`;

    // Check if the number is greater than or equal to 1 thousand
    if (absValue >= 1.0e3) return `${(absValue / 1.0e3).toFixed(2)}K`;

    // Return the number as is
    return String(absValue);
};

export const getUrlParamsDashboard = (url: string) => {
    const isDashboard = REGEX_IS_DASHBOARD.test(url);
    const isGuildId = REGEX_GUILD_ID.test(url);
    const isBotId = REGEX_BOT_TYPE.test(url);
    const guildId = REGEX_GUILD_ID.exec(url) ?? [];
    const botId = REGEX_BOT_TYPE.exec(url) ?? [];

    return {
        isDashboard,
        isGuildId,
        isBotId,
        guildId: guildId[1],
        botId: botId[1]
    };
};

export const fixUsername = (username: string) => {
    const newUsername = username.split("#")[1].length < 4 ? username.split("#")[0] : username;

    return newUsername.split(" ")[0];
};
