import environments from "@/lib/environments/environments";
import type { Client, DashboardState, GuildConfig } from "@/redux/slices/dashboard/types";

const { webUrl } = environments;

const useDashboard = () => {
    const logoutUser = async (): Promise<boolean | undefined> => {
        try {
            const response = await fetch(`${webUrl}es/api?endpoint=USER_LOGOUT`, {
                method: "POST"
            });

            const responseData = (await response.json()) as boolean;

            return responseData;
        } catch {
            return undefined;
        }
    };

    const getUser = async (): Promise<Client | undefined> => {
        try {
            const response = await fetch(`${webUrl}es/api?endpoint=USER`, {
                method: "GET"
            });

            const responseData = (await response.json()) as Client;

            const MANAGE_GUILD = 0x20;
            const filtredGuidlds = responseData.guilds
                .filter(guild => guild.permissions & MANAGE_GUILD)
                .map(guild => ({
                    ...guild,
                    icon: guild.icon
                        ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
                        : "https://cdn.discordapp.com/embed/avatars/0.png"
                }));

            return {
                ...responseData,
                guilds: filtredGuidlds
            };
        } catch {
            return undefined;
        }
    };

    const getBotMutualguilds = async (botId: string): Promise<Client["guilds"]> => {
        try {
            const response = await fetch(`${webUrl}es/api?endpoint=BOT_MUTUAL_GUILDS&botId=${botId}`, {
                method: "GET"
            });

            const responseData = (await response.json()) as {
                data: Client["guilds"];
            };

            return responseData.data;
        } catch {
            return [];
        }
    };

    const getGuild = async (guildId: string, botId: string): Promise<DashboardState["guild"]> => {
        try {
            const response = await fetch(`${webUrl}es/api?endpoint=BOT_GUILD&guildId=${guildId}&botId=${botId}`, {
                method: "GET"
            });

            const responseData = (await response.json()) as {
                data: DashboardState["guild"];
            };

            return responseData.data;
        } catch {
            return undefined;
        }
    };

    const updateGuild = async (guildId: string, botId: string, config: GuildConfig): Promise<string | undefined> => {
        try {
            const response = await fetch(`${webUrl}es/api?endpoint=BOT_UPDATE_GUILD&guildId=${guildId}&botId=${botId}`, {
                method: "POST",
                body: JSON.stringify(config)
            });

            const responseData = (await response.json()) as {
                message: string;
            };

            return responseData.message;
        } catch {
            return undefined;
        }
    };

    return {
        logoutUser,
        getUser,
        getBotMutualguilds,
        getGuild,
        updateGuild
    };
};

export default useDashboard;
