import dataJSON from "@data/teams.json";
import type { Member, Team } from "./types";

const members = dataJSON as Member[];

const getTeams = (ids: string[]): Member[] => members.filter(member => ids.includes(member.id));

const teams: Team = {
    teams: getTeams(["581527728034152449", "397453373479190538", "403917639673577482", "546000599267672074"]),
    contributors: getTeams([
        "339129842908004354",
        "650899812388044830",
        "521379741387718697",
        "172563866755137538",
        "720066011356856380",
        "816475179105648692",
        "816419890545295362",
        "608420982092333059",
        "680189998750105711",
        "459649180969730050",
        "486936324436262925",
        "742230340805984407",
        "491940828349071380",
        "981969441875169360",
        "000000000000000000"
    ]),
    bots: getTeams(["910965586622816326", "910965623671119942", "910965659851178054", "910978145610526761", "custom"])
};

export default teams;
