import { createGlobalStyle, css } from "styled-components";
import globals from "./globals/globals";

type StyledComponentesProps = {
    $isGuild: boolean;
};

const StyledComponentes = css<StyledComponentesProps>`
    html,
    body,
    #__next,
    * {
        font-family: ${globals.fonts.manrope} sans-serif;
    }

    body {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
        ${props => props.$isGuild && "overflow-y: hidden"};
        background-color: ${globals.colors.primary};
    }

    .rounded {
        border-radius: 999px;
    }

    .hidden {
        display: none !important;
    }

    .invisible {
        /* webkit-filter: blur(7px); */
        filter: blur(7px);
    }

    .soon {
        position: relative;

        &-text {
            z-index: 999;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: 800;
            text-transform: uppercase;

            @media (max-width: ${globals.sizes.mobileMedium}) {
                font-size: 1.5rem;
            }

            @media (max-width: ${globals.sizes.mobileLarge}) {
                font-size: 2rem;
            }

            @media (max-width: ${globals.sizes.desktopSmall}) {
                font-size: 2.5rem;
            }
        }
    }
`;

export default createGlobalStyle<StyledComponentesProps>`${StyledComponentes}`;
