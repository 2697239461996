import dataJSON from "@/data/heads.json";
import { getUrlParamsDashboard } from "@/utils/format";
import i18n from "../../../i18n.config.cjs";
import type Heads from "./types";

const { locales } = i18n;
export const { heads, keywords } = dataJSON as Heads;

export const pageHead = (path: string) => {
    const { isDashboard } = getUrlParamsDashboard(path);

    if (isDashboard) {
        return {
            ...heads.at(-1),
            keywords
        };
    }

    const headFinded = heads.find(head => {
        const locale = locales.find(locale => path.includes(locale)) ?? locales[0];
        const parsedUrl = path.replace(`/${locale}`, "");

        return head.path === parsedUrl;
    });

    const head = headFinded ?? heads[0];

    return {
        ...head,
        keywords
    };
};
