"use client";

import type { PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import StyledComponentes from "./StyledComponents";
import StyledComponentsRegistry from "./StyledComponentsRegistry";
import globals from "./globals/globals";

const StyledComponentsProviders: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledComponentsRegistry>
        <ThemeProvider theme={globals}>
            <StyledComponentes $isGuild={false} />
            {children}
        </ThemeProvider>
    </StyledComponentsRegistry>
);

export default StyledComponentsProviders;
