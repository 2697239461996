import type { GlobalFonts } from "@/context/StyledComponents/types/types";
import { Manrope, Montserrat } from "next/font/google";

const montserrat = Montserrat({
    subsets: ["latin"],
    weight: ["400", "500", "600", "700", "800"],
    variable: "--font-montserrat"
});

const manrope = Manrope({
    subsets: ["latin"],
    weight: ["400", "500", "600", "700", "800"],
    variable: "--font-manrope"
});

const globalFonts: GlobalFonts = {
    manrope,
    montserrat
};

export default globalFonts;
