const getTheme = theme => {
    if (theme === "navidad") {
        return {
            default: "#A81F31",
            defaultDark: "#d62d3f"
        };
    }

    if (theme === "halloween") {
        return {
            default: "#ff6f00",
            defaultDark: "#d86004"
        };
    }

    return {
        default: "#5865f2",
        defaultDark: "#4752c5"
    };
};

module.exports = getTheme;
