"use client";

import useUiActions from "@/hooks/ui/useUiActions";
import { useEffect } from "react";

const LayoutRoot: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { goUp, toggleGoUp } = useUiActions();

    useEffect(() => {
        const handleResize = () => {
            const scrollTopBody = document.body.scrollTop;
            const scrollTopDocument = document.documentElement.scrollTop;

            if (scrollTopBody > 100 || scrollTopDocument > 100) {
                toggleGoUp(scrollTopBody > 20 || scrollTopDocument > 300);
            } else if (goUp) toggleGoUp(false);
        };

        window.addEventListener("scroll", handleResize, true);
        return () => {
            window.removeEventListener("scroll", handleResize, true);
        };
    }, [goUp, toggleGoUp]);

    return <main>{children}</main>;
};

export default LayoutRoot;
