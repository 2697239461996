"use client";

import { useParamsClient } from "@/hooks/params";
import LayoutDashboard from "@/layouts/LayoutDashboard";
import LayoutDashboardGuild from "@/layouts/LayoutDashboardGuild";
import LayoutRoot from "@/layouts/LayoutRoot";
import LayoutUser from "@/layouts/LayoutUser";
import { store } from "@/redux/store";
import { Provider as ReduxProvider } from "react-redux";
import StyledComponentsProviders from "./StyledComponents/StyledComponentsProviders";

type ProvidersProps = {
    children: React.ReactNode;
    isCookieSession: boolean;
};

const Providers: React.FC<ProvidersProps> = ({ children, isCookieSession }) => {
    const { isDashboard } = useParamsClient();

    return (
        <StyledComponentsProviders>
            <ReduxProvider store={store}>
                <LayoutRoot>
                    <LayoutUser isCookieSession={isCookieSession}>
                        {isDashboard && (
                            <LayoutDashboard>
                                <LayoutDashboardGuild>{children}</LayoutDashboardGuild>
                            </LayoutDashboard>
                        )}
                        {!isDashboard && children}
                    </LayoutUser>
                </LayoutRoot>
            </ReduxProvider>
        </StyledComponentsProviders>
    );
};

export default Providers;
