import type { GlobalColors } from "@/context/StyledComponents/types/types";
import environments from "@/lib/environments/environments";
import themes from "../../../../theme.config.cjs";

const { globalTheme } = environments;

const theme = themes(globalTheme);

const globalColors: GlobalColors = {
    primary: "#141518",
    secondary: "#202124",
    tertiary: "#282E33",
    tertiaryLight: "#31364d",
    quaternary: "#58608a",
    quaternaryDark: "#58608ab3",
    premium: "#eab308",
    success: "#70ff87",
    warning: "#FEE75C",
    error: "#ed4245",
    errorDark: "#d62d3f",
    default: theme.default,
    defaultDark: theme.defaultDark,
    fontMain: "#fff",
    fontSecondary: "#b0b0b0"
};

export default globalColors;
