import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
    addGuildActionCreator,
    changeBotStateActionCreator,
    changeGuildStateActionCreator,
    changeUserStateActionCreator,
    loadBotActionCreator,
    loadBotsActionCreator,
    loadGuildActionCreator,
    loadGuildConfigActionCreator,
    loadUserActionCreator,
    loadUserGuildsActionCreator,
    removeUserActionCreator,
    toggleAreChangesActionCreator,
    updateGuildsActionCreator
} from "@/redux/slices/dashboard/slice";
import type { DashboardState, GuildConfigState } from "@/redux/slices/dashboard/types";

const useDashboardActions = () => {
    const dispatch = useAppDispatch();
    const dashboard = useAppSelector(state => state.dashboard);

    const removeUser = () => {
        dispatch(removeUserActionCreator());
    };

    const loadUser = (payload: DashboardState["user"]) => {
        dispatch(loadUserActionCreator(payload));
    };

    const loadUserGuilds = (payload: DashboardState["user"]["guilds"]) => {
        dispatch(loadUserGuildsActionCreator(payload));
    };

    const loadBot = (payload: DashboardState["bot"]) => {
        dispatch(loadBotActionCreator(payload));
    };

    const loadBots = (payload: DashboardState["bots"]) => {
        dispatch(loadBotsActionCreator(payload));
    };

    const loadGuild = (payload: DashboardState["guild"]) => {
        dispatch(loadGuildActionCreator(payload));
    };

    const addGuild = (payload: DashboardState["guilds"][0]) => {
        dispatch(addGuildActionCreator(payload));
    };

    const updateGuild = (payload: {
        botId: string;
        guildId: string;
        guildConfig: DashboardState["guildConfig"];
        guildData: DashboardState["guilds"][0]["guild"]["guild_data"];
    }) => {
        dispatch(updateGuildsActionCreator(payload));
    };

    const loadGuildConfig = (payload: GuildConfigState) => {
        dispatch(loadGuildConfigActionCreator(payload));
    };

    const toggleAreChanges = (payload: DashboardState["areChanges"]) => {
        dispatch(toggleAreChangesActionCreator(payload));
    };

    const changeUserState = (payload: DashboardState["userState"]) => {
        dispatch(changeUserStateActionCreator(payload));
    };

    const changeBotState = (payload: DashboardState["botState"]) => {
        dispatch(changeBotStateActionCreator(payload));
    };

    const changeGuildState = (payload: DashboardState["guildState"]) => {
        dispatch(changeGuildStateActionCreator(payload));
    };

    return {
        ...dashboard,
        removeUser,
        loadUser,
        loadUserGuilds,
        loadBot,
        loadBots,
        loadGuild,
        addGuild,
        updateGuild,
        loadGuildConfig,
        toggleAreChanges,
        changeUserState,
        changeBotState,
        changeGuildState
    };
};

export default useDashboardActions;
