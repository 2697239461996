import teams from "@/lib/teams";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { DashboardState, GuildConfigState } from "./types";

// const initialBot = teams.bots[0];
const initialBots = teams.bots.map(bot => ({
    id: bot.id,
    username: bot.name,
    avatar: bot.avatar,
    guilds: []
}));

export const dashboardInitialState: DashboardState = {
    userState: "empty",
    botState: "empty",
    guildState: "empty",
    user: {
        id: "",
        username: "",
        avatar: "",
        guilds: []
    },
    bot: {} as unknown as DashboardState["bot"],
    bots: initialBots,
    guild: undefined,
    guildConfig: {} as unknown as DashboardState["guildConfig"],
    guilds: [],
    areChanges: false
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: dashboardInitialState,
    reducers: {
        removeUser(state) {
            state.user = dashboardInitialState.user;
        },
        loadUser(state, action: PayloadAction<DashboardState["user"]>) {
            state.user = action.payload;
        },
        loadUserGuilds(state, action: PayloadAction<DashboardState["user"]["guilds"]>) {
            if (state?.user?.guilds.length >= 0) {
                state.user.guilds = action.payload;
            }
        },
        loadBot(state, action: PayloadAction<DashboardState["bot"]>) {
            state.bot = action.payload;
        },
        loadBots(state, action: PayloadAction<DashboardState["bots"]>) {
            state.bots = action.payload;
        },
        loadGuild(state, action: PayloadAction<DashboardState["guild"]>) {
            state.guild = action.payload;
        },
        addGuild(state, action: PayloadAction<DashboardState["guilds"][0]>) {
            if (action.payload) state.guilds.push(action.payload);
        },
        updateGuilds(
            state,
            action: PayloadAction<{
                botId: string;
                guildId: string;
                guildConfig: DashboardState["guildConfig"];
                guildData: DashboardState["guilds"][0]["guild"]["guild_data"];
            }>
        ) {
            if (action.payload) {
                state.guilds = state.guilds.map(item => {
                    if (item.guild?.id === action.payload.guildId && item.botId === action.payload.botId) {
                        return {
                            botId: item.botId,
                            guild: {
                                ...item.guild,
                                config: action.payload.guildConfig,
                                guild_data: action.payload.guildData
                            }
                        };
                    }

                    return item;
                });
            }
        },
        loadGuildConfig(state, action: PayloadAction<GuildConfigState>) {
            const { option, data } = action.payload;

            if (option === "reset") {
                state.guildConfig = data;
            } else if (option === "silent") {
                state.guildConfig.silent = data;
            } else if (option === "premium") {
                state.guildConfig.premium = data;
            } else if (option === "color") {
                state.guildConfig.color = data;
            } else if (option === "advanced") {
                const { select } = action.payload;

                state.guildConfig.advanced[select] = data as keyof GuildConfigState["data"];
            } else if (option === "disabled") {
                const { select } = action.payload;

                state.guildConfig.disabled[select] = data;
            } else if (option === "lang") {
                state.guildConfig.lang = data;
            } else if (option === "djRole") {
                state.guildConfig.djRole = data;
            } else if (option === "beta") {
                state.guildConfig.beta = data;
            }
        },
        toggleAreChanges(state, action: PayloadAction<DashboardState["areChanges"]>) {
            state.areChanges = action.payload;
        },
        changeUserState(state, action: PayloadAction<DashboardState["userState"]>) {
            state.userState = action.payload;
        },
        changeBotState(state, action: PayloadAction<DashboardState["botState"]>) {
            state.botState = action.payload;
        },
        changeGuildState(state, action: PayloadAction<DashboardState["guildState"]>) {
            state.guildState = action.payload;
        }
    }
});

export const dashboardReducer = dashboardSlice.reducer;
export const {
    removeUser: removeUserActionCreator,
    loadUser: loadUserActionCreator,
    loadUserGuilds: loadUserGuildsActionCreator,
    loadBot: loadBotActionCreator,
    loadBots: loadBotsActionCreator,
    loadGuild: loadGuildActionCreator,
    addGuild: addGuildActionCreator,
    updateGuilds: updateGuildsActionCreator,
    loadGuildConfig: loadGuildConfigActionCreator,
    toggleAreChanges: toggleAreChangesActionCreator,
    changeUserState: changeUserStateActionCreator,
    changeBotState: changeBotStateActionCreator,
    changeGuildState: changeGuildStateActionCreator
} = dashboardSlice.actions;
